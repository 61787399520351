import { IContentPartProps } from "../../interfaces";
import { Icon } from "../../themes/ItemStyles";
import "./ContentPartStyle.scss";
export default function ContentPart(props: IContentPartProps) {
    const classname = "outerComponentCenterContainer " + props.OverrideThemeClassName;
    const ImageSide = props.IsImageOnLeft ? "LShapeImageonLeft" : "LShapeImageonRight ";
    return (<div className={classname} id="ContentPart">
        <div className="innerComponentContainer">
            <div dangerouslySetInnerHTML={{ __html: props.Title }} />

            {!props.IconColor && <div dangerouslySetInnerHTML={{ __html: props.Details.join("") }} />}
            {props.IconColor && <>       <div className={"CardIconAreaContainer"}>
                <div className="CardIconArea" style={{ backgroundColor: props.IconBackColor }}>
                    {
                        <Icon IconName={props.Icon!} size={"6vw"} fill={props.IconColor!} />
                    }
                </div>

                <div className="sideTextArea" dangerouslySetInnerHTML={{ __html: props.Details.join("")! }} />
            </div>
                <hr /></>
            }
            <div className={ImageSide} dangerouslySetInnerHTML={{ __html: props.Content.join("") }} />
        </div>
    </div>
    );
}