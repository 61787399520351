import { Dispatch, SetStateAction, createContext } from 'react';

export interface ISettingsContext {
  language: string;
  setLanguage: Dispatch<SetStateAction<string>>;
}

export const SettingsContext = createContext<ISettingsContext>({
    language: "",
    setLanguage: () => {},
  });