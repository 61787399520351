import { ITitleWithIconProps } from "../../interfaces";
import "./TitleWithIconStyle.scss";
import { Icon } from '../../themes/ItemStyles';

export default function TitleWithIcon(props: ITitleWithIconProps) {
    const classname = "outerComponentCenterContainer " + props.OverrideThemeClassName;
    return (<div className={classname} id="TitleWithIcon">
        <div className="innerComponentContainer">
            <div className="CardContainer" style={{ backgroundColor: props.BackgroundColor, color: props.Color }}>
                <div>
                    <div dangerouslySetInnerHTML={{ __html: props.Title }} />
                    <div dangerouslySetInnerHTML={{ __html: props.Description! }} />
                </div>
                <div className="IconArea">
                    <Icon IconName={props.Icon} size={"50%"} fill={props.Color} />
                </div>
            </div>
        </div>
    </div>
    );
}