import { Outlet } from "react-router-dom";
import Header from "../components/Header/Header";
import CookieConsent from "react-cookie-consent";
import { ILayOutData, IPageProps } from '../interfaces'
import { useState, useEffect } from "react";
import Footer from "../components/Footer/Footer";
import { CompanyAddress, CompanyInitialName, JobLink, PostLink } from "../constants";

export default function Layout(props: IPageProps) {
  const [data, setData] = useState<ILayOutData>();
  const [dataLoading, setDataLoading] = useState(true);
  useEffect(() => {
    const fetchData = () => {
      fetch(props.ContentFileName)
        .then(res => res.text())
        .then(text => JSON.parse(text
          .replaceAll('{CompanyInitialName}', CompanyInitialName)
          .replaceAll('{JobLink}', JobLink)
          .replaceAll('{PostLink}', PostLink)
          .replaceAll('{CompanyAddress}', CompanyAddress))
        )
        .then((cdnPageData) => {
          setData(cdnPageData);
          setDataLoading(false);
        })
        .catch((e) => {
          setDataLoading(true);
          console.log(e);
        });
    };
    fetchData();
  }, [props.ContentFileName]);
  return (<div id="layoutContainer">
    {!dataLoading && <Header {...data!.HeaderData} />}
    <Outlet />
    {!dataLoading && <Footer {...data!.FooterData} />}
    {!dataLoading &&
      <CookieConsent enableDeclineButton
        location={data!.CookieData!.Location}
        buttonText={data!.CookieData!.ButtonText}
        buttonStyle={{ background: '#aacbed', fontSize: 'clamp(12px, 1.2vw, 20px)'}}
        declineButtonText={data!.CookieData!.DeclineButtonText}
        declineButtonStyle={{ background: '#07396e', fontSize: 'clamp(12px, 1.2vw, 20px)'}}
        cookieName="sankhyatronics"
        style={{ background: '#0c64c5', fontSize: 'clamp(12px, 1.2vw, 20px)' }}
        expires={150}>
        {data!.CookieData!.CookieConsent}
      </CookieConsent>}
  </div>
  )
};