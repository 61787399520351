import { useState, useEffect } from 'react';
import Hero from '../components/Hero/Hero';
import { IPageData, IPageProps } from '../interfaces';
import PageCard from '../components/PageCard/PageCard';
import InfoSection from '../components/InfoSection/InfoSection';
import FullWIdthInfoSection from '../components/FullWIdthInfoSection/FullWIdthInfoSection';
import "./Pages.scss";
import { CompanyAddress, CompanyInitialName, JobLink, PostLink } from '../constants';

export default function Home(props: IPageProps) {
  const [data, setData] = useState<IPageData>();
  const [dataLoading, setDataLoading] = useState(true);
  useEffect(() => {
    const fetchData = () => {
      fetch(props.ContentFileName)
        .then(res => res.text())
        .then(text => JSON.parse(text
          .replaceAll('{CompanyInitialName}', CompanyInitialName)
          .replaceAll('{JobLink}', JobLink)
          .replaceAll('{PostLink}', PostLink)
          .replaceAll('{CompanyAddress}', CompanyAddress))
        )
        .then((cdnPageData) => {
          setData(cdnPageData);
          setDataLoading(false);
        })
        .catch((e) => {
          setDataLoading(true);
          console.log(e);
        });
    };
    fetchData();
  }, [props.ContentFileName]);
  return <>
    {!dataLoading &&
      <>
        <Hero {...data!.HeroWebparts![0]} />
        <PageCard {...data!.PageCards![0]} />
        <InfoSection {...data!.InfoCards![0]} />
        <PageCard {...data!.PageCards![1]} />
        <FullWIdthInfoSection {...data!.FullWIdthInfoSection![0]} />
      </>
    }
  </>
}