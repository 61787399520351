import { useState, useEffect } from 'react';
import { IContentPartProps, IPageData, IPageProps } from '../interfaces';
import { Icon } from '../themes/ItemStyles';
import { CompanyAddress, CompanyInitialName, JobLink, PostLink } from '../constants';

export default function Contact(props: IPageProps) {

    const [data, setData] = useState<IPageData>();
    const [dataLoading, setDataLoading] = useState(true);
    useEffect(() => {
        const fetchData = () => {
            fetch(props.ContentFileName)
                .then(res => res.text())
                .then(text => JSON.parse(text
                    .replaceAll('{CompanyInitialName}', CompanyInitialName)
                    .replaceAll('{JobLink}', JobLink)
                    .replaceAll('{PostLink}', PostLink)
                    .replaceAll('{CompanyAddress}', CompanyAddress))
                )
                .then((cdnPageData) => {
                    setData(cdnPageData);
                    setDataLoading(false);
                })
                .catch((e) => {
                    setDataLoading(true);
                    console.log(e);
                });
        };
        fetchData();
    }, [props.ContentFileName]);
    return <>
        {!dataLoading && getRightIconCard(data!.Contents![0])
        }
    </>
}
function getRightIconCard(props: IContentPartProps) {
    const classnametoapply = "outerComponentCenterContainer " + props.OverrideThemeClassName;
    return (
        <div className={classnametoapply} id="PageCard">
            <div className="innerComponentContainer">
                {props.Title && <div className="PageCardTitle" dangerouslySetInnerHTML={{ __html: props.Title }} />}
                {props.Details && <div className="PageCardSubTitle" dangerouslySetInnerHTML={{ __html: props.Details[0] }} />}
                <br /><br /><br /><br />
                <div className="TwoColumnContainer">
                    <div className='TwoColumnAuto' id='Danmark'>
                        <Icon IconName={props.Content![0]} size={"75%"} fill={'#0c64c5'} />
                        <div dangerouslySetInnerHTML={{ __html: props.Content![2] }} />
                        <div></div>
                        <div dangerouslySetInnerHTML={{ __html: props.Content![3] }} />
                        <div>
                            <Icon IconName={props.Content![1]} size={"75%"} fill='#0c64c5' />
                        </div>
                        <div dangerouslySetInnerHTML={{ __html: props.Content![4] }} />
                    </div>
                    {props.Content[5] && <div className='TwoColumnAuto' id='Bharat'>
                        <Icon IconName={props.Content![0]} size={"75%"} fill='#0c64c5' />
                        <div dangerouslySetInnerHTML={{ __html: props.Content![5] }} />
                        <div></div>
                        <div dangerouslySetInnerHTML={{ __html: props.Content![6] }} />
                        <div>
                            <Icon IconName={props.Content![1]} size={"75%"} fill={'#0c64c5'} />
                        </div>
                        <div dangerouslySetInnerHTML={{ __html: props.Content![7] }} />
                    </div>}
                </div><br /><br />
            </div>
        </div>);
}
