import { IHeroWebPartProps } from "../../interfaces";
import ScheduleCall from "../ScheduleCall/ScheduleCall";
import { HashLink as Link } from 'react-router-hash-link';
import "./HeroStyle.scss";
export default function Hero(props: IHeroWebPartProps) {
    return (<div id="HeroPart" style={{ backgroundImage: 'url(' + props.BackgroundImage + ')' }}>
        <div className="HeroContent">
            <div className="heroTitle" dangerouslySetInnerHTML={{ __html: props.Title }} />
            <div className="heroSubTitle" dangerouslySetInnerHTML={{ __html: props.SubTitle }} />
            <div className="heroAction">
                <div >
                    <ScheduleCall IsExtraWide={true} IsInvertColour={true} BookingLink={props.PrimarybuttonLink} ButtonText={props.PrimarybuttonText!} />
                </div>
                <div >
                    <Link className='TransprentButton' to={props.SecondarybuttonLink!} key={props.SecondarybuttonText} >
                        {props.SecondarybuttonText}
                    </Link>
                </div>
            </div>
        </div>
    </div>
    );
}