import { IScheduleCall } from '../../interfaces';
import React, { useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import "./ScheduleCall.scss";
import { Icon } from '../../themes/ItemStyles';

export default function ScheduleCall(props: IScheduleCall) {
    let classname = "ScheduleButton"
    classname += props.IsExtraWide ? " ExtraWideButton" : "";
    classname += props.IsInvertColour ? " InvertButton" : " NormalButton";
    const iconColor = props.IsInvertColour ? '#0c64c5' : "#ffffff";
    const [isOpen, openPanel] = useState(false);
    return <div id="ScheduleCall">
        <Link className={classname} to={''} key={props.ButtonText} onClick={() => openPanel(true)}>
            <Icon IconName={"Phone"} size={"clamp(12px, 1.56vw, 24px)"} fill={iconColor} /> {props.ButtonText}
        </Link>
        <div className={isOpen ? 'OpenedPanel' : 'ClosedPanel'}>
            <div className={"PanelContainer"}>
                <div className={"PanelHeader"}>
                    <div onClick={() => openPanel(false)}>
                        <Icon IconName={"Close"} size={"48px"} fill={'#0c64c5'} />
                    </div>
                </div>
                <div className={"PanelContent"}>
                    <iframe title="schedulecall" src={props.BookingLink} style={{ height: '100%', width: '100%', border: '0' }} ></iframe>
                </div>
            </div>
        </div>
    </div>
}
