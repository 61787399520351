import { IInfoCardProps } from "../../interfaces";
import ScheduleCall from "../ScheduleCall/ScheduleCall";
import "./FullWIdthInfoSectionStyle.scss";
export default function FullWIdthInfoSection(props: IInfoCardProps) {
    const classname = "outerComponentCenterContainer " + props.OverrideThemeClassName;
    return (<div className={classname} id="FullWIdthInfoSection">
        <div className="innerComponentContainer">
            <div className="CallContainer">
                <div>
                    <div className="FullInfoCardTitle" dangerouslySetInnerHTML={{ __html: props.Title }} />
                    <div className="FullInfoCardDetails" dangerouslySetInnerHTML={{ __html: props.Description! }} />
                </div>
                <div className="CallUsRight">
                    {props.ShowCallButton && <ScheduleCall BookingLink={props.BookingLink!} ButtonText={props.ButtonText!} />}
                </div>
            </div>
        </div>
    </div>
    );
}