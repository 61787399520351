import { InfoCard212Props } from "../../interfaces";
import { Icon } from "../../themes/ItemStyles";
import "./InfoCard212Style.scss";

export default function InfoCard212(props: InfoCard212Props) {
    const classname = "outerComponentCenterContainer " + props.OverrideThemeClassName;
    return (<div className={classname} id="InfoCard212">
        <div className="innerComponentContainer">
            {props.Title && <div className="ContentTitle" dangerouslySetInnerHTML={{ __html: props.Title }} />}
            <div className="TextSectionInfoCard212Container">
                <div className="InfoCard212Left">
                    <div className="topArea" dangerouslySetInnerHTML={{ __html: props.TextSections[0] }} />
                    {props.Icon &&
                        <div className="bottomArea">
                            <Icon IconName={props.Icon!} size={"65%"} fill={props.IconColor!} />
                        </div>}
                </div>
                <div className="InfoCard212Right" dangerouslySetInnerHTML={{ __html: props.TextSections[1] }} />
            </div>
        </div>
        {props.IsMiddleImage && <div className="ImageSectionInfoCard212Container">
            &nbsp;
        </div>}

        <div className="innerComponentContainer">
            <div className="TextSectionInfoCard212Container">
                <div className="InfoCard212Left" dangerouslySetInnerHTML={{ __html: props.TextSections[2] }} />
                <div className="InfoCard212Right" dangerouslySetInnerHTML={{ __html: props.TextSections[3] }} />
            </div>
        </div>
    </div>
    );
}