import { BrowserRouter, Route, Routes, } from "react-router-dom";
import Layout from './Pages/Layout';
import { GOOGLE_MEASUREMENT_ID, defaultLanguage } from './constants';
import ReactGA from "react-ga4";
import Home from './Pages/Home';
import About from './Pages/About';
import { Services } from './Pages/Services';
import ContentPage from './Pages/ContentPage';
import Contact from './Pages/Contact';
import SkillCatalog from './Pages/SkillCatalog';
import { useState } from "react";
import { SettingsContext } from "./Context";

export default function App() {
  ReactGA.initialize(GOOGLE_MEASUREMENT_ID);
  ReactGA.send(window.location.pathname === '/' ? "" : window.location.pathname);
  const [language, setLanguage] = useState(defaultLanguage);
  return (<SettingsContext.Provider value={{ language, setLanguage }}>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout ContentFileName={'./' + language + "/layoutData.json"} />}>
          <Route index element={<Home ContentFileName={'./' + language + "/Home.json"} />} />
          <Route path="Home" element={<Home ContentFileName={'./' + language + "/Home.json"} />} />
          <Route path="About" element={<About ContentFileName={'./' + language + "/About.json"} />} />

          <Route path="Services" element={<Services ContentFileName={'./' + language + "/Services.json"} />} />
          <Route path="ServicesDetails" element={<ContentPage ContentFileName={'./' + language + "/ServicesDetails.json"} />} />
          <Route path="Outsourcing" element={<ContentPage ContentFileName={'./' + language + "/Outsourcing.json"} />} />
          <Route path="Talent" element={<ContentPage ContentFileName={'./' + language + "/Talent.json"} />} />
          <Route path="Pricing" element={<ContentPage ContentFileName={'./' + language + "/Pricing.json"} />} />
          <Route path="ReliableService" element={<ContentPage ContentFileName={'./' + language + "/ReliableService.json"} />} />

          <Route path="Contact" element={<Contact ContentFileName={'./' + language + "/Contact.json"} />} />

          <Route path="Privacy-Policy" element={<ContentPage ContentFileName={'./' + language + "/Policy.json"} />} />
          <Route path="Cookie-Policy" element={<ContentPage ContentFileName={'./' + language + "/Policy.json"} />} />
          <Route path="Copyright-Notice" element={<ContentPage ContentFileName={'./' + language + "/Policy.json"} />} />
          <Route path="Disclaimer" element={<ContentPage ContentFileName={'./' + language + "/Policy.json"} />} />
          <Route path="Terms-of-Sale" element={<ContentPage ContentFileName={'./' + language + "/Policy.json"} />} />
          <Route path="Terms-of-Service" element={<ContentPage ContentFileName={'./' + language + "/Policy.json"} />} />

          <Route path="ModernWorkspace" element={<SkillCatalog ContentFileName={'./' + language + "/ModernWorkspace.json"} />} />
          <Route path="CloudServices" element={<SkillCatalog ContentFileName={'./' + language + "/CloudServices.json"} />} />
          <Route path="UserExperience" element={<SkillCatalog ContentFileName={'./' + language + "/UserExperience.json"} />} />
          <Route path="BusinessOperations" element={<SkillCatalog ContentFileName={'./' + language + "/BusinessOperations.json"} />} />
          <Route path="ApplicationDevelopment" element={<SkillCatalog ContentFileName={'./' + language + "/ApplicationDevelopment.json"} />} />
          <Route path="AIServices" element={<SkillCatalog ContentFileName={'./' + language + "/AIServices.json"} />} />

          <Route path="*" element={<ContentPage ContentFileName={'./' + language + "/NoPage.json"} />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </SettingsContext.Provider >
  );
}