import { useState, useEffect } from "react";
import ContentPart from "../components/ContentPart/ContentPart";
import { IPageProps, IPageData } from "../interfaces";
import { CompanyAddress, CompanyInitialName, JobLink, PostLink } from "../constants";

export default function ContentPage(props: IPageProps) {
  const [data, setData] = useState<IPageData>();
  const [dataLoading, setDataLoading] = useState(true);
  useEffect(() => {
    const fetchData = () => {
      fetch(props.ContentFileName)
        .then(res => res.text())
        .then(text => JSON.parse(text
          .replaceAll('{CompanyInitialName}', CompanyInitialName)
          .replaceAll('{JobLink}', JobLink)
          .replaceAll('{PostLink}', PostLink)
          .replaceAll('{CompanyAddress}', CompanyAddress))
        )
        .then((cdnPageData) => {
          setData(cdnPageData);
          setDataLoading(false);
        })
        .catch((e) => {
          setDataLoading(true);
          console.log(e);
        });
    };
    fetchData();
  }, [props.ContentFileName]);
  return <>
    {!dataLoading &&
      <>
        {!dataLoading && data!.Contents.map((item) => (<ContentPart {...item} />))}
      </>
    }
  </>
}