import { IHeaderProp } from '../../interfaces';
import ScheduleCall from '../ScheduleCall/ScheduleCall';
import { useContext, useState } from 'react';
import "./HeaderStyle.scss";
import { HashLink as Link } from 'react-router-hash-link';
import { Icon } from '../../themes/ItemStyles';
import { SettingsContext } from '../../Context';
export default function Header(props: IHeaderProp) {
  const activePage = props.menuItems!.filter(activePage => activePage.url.toLowerCase() === window.location.pathname.toLowerCase());
  const [isOpen, openPanel] = useState(false);
  const [openClass, setOpen] = useState("IconDropDown-content");
  const [active, setActive] = useState(activePage.length > 0 ? activePage[0].id : -1);
  const usersettingValues = useContext(SettingsContext);
  let uniqueKey = 0;
  return (<div className="outerComponentCenterContainer" id="SiteHeader" key={"header" + uniqueKey++}>
    <div className="menuComponentContainer" key={"header" + uniqueKey++}>
      <div className='LogoContainer' key={"header" + uniqueKey++}>
        <Link onClick={() => setActive(-1)} key={"header" + uniqueKey++} to="/" preventScrollReset={false} >
          <img src='./Logo.png' alt="logo" className='logoImage' key={"header" + uniqueKey++} />
        </Link>
      </div>
      <div className="HideOnMobile" key={"header" + uniqueKey++}>
        <div className="navbar" key={"header" + uniqueKey++}>
          {props.menuItems!.map((item) => (
            item.SubItems === undefined ?
              <div key={"header" + uniqueKey++} className={"dropdown"} data-active={(item.id === active && item.id > -1) || undefined}>
                <Link to={item.url} key={"header" + uniqueKey++} target={item.target}
                  onClick={() => { if (item.id > -1) setActive(item.id); }}>
                  {item.label}
                </Link></div>
              :
              <div key={"header" + uniqueKey++} className={"dropdown"} data-active={(item.id === active && item.id > -1) || undefined}>
                <div key={"header" + uniqueKey++} className="dropbtn" >{item.label}
                  <Icon IconName={"ChevronDown"} size={"16px"} fill={'#0c64c5'} />
                </div>
                <div key={"header" + uniqueKey++} className="dropdown-content" id="serviceDropdown">
                  {item.SubItems!.map((subitem) => (
                    <Link to={subitem.url} key={"header" + uniqueKey++} target={subitem.target} onClick={() => setActive(item.id)}  >
                      {subitem.label}
                    </Link>
                  ))}
                </div>
              </div>
          ))}
        </div></div>
      <div key={"header" + uniqueKey++} className="HideOnMobile"><div key={"header" + uniqueKey++} className='actionContainer HideOnMobile'>
        <ScheduleCall key={"header" + uniqueKey++} BookingLink={props.ScheduleCall!.BookingLink} ButtonText={props.ScheduleCall!.ButtonText} />
      </div></div>
      <div key={"header" + uniqueKey++} className='humburgItemsContainer'>
        <div onClick={() => openPanel(true)} key={"header" + uniqueKey++}>
          <Icon IconName={"Humburg"} size={"36px"} fill={'#0c64c5'} />
        </div>
      </div>
      <div key={"header" + uniqueKey++} className={"IconDropDown"}>
        <div key={"header" + uniqueKey++} className="IconDropBtn" >
          <div key={"header" + uniqueKey++}>
            <Icon IconName={usersettingValues.language} size={"30px"} />
          </div>
        </div>
        <div key={"header" + uniqueKey++} className={openClass} id="languageDropdown">
          {props.LanguageOptions!.map((subitem) => (
            <div key={"header" + uniqueKey++} >
              <div onClick={() => { usersettingValues.setLanguage(subitem); setOpen("IconDropDown-content") }}>
                <Icon IconName={subitem} size={"30px"} />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div key={"header" + uniqueKey++} className={isOpen ? 'OpenedPanel' : 'ClosedPanel'}>
        <div key={"header" + uniqueKey++} className={"PanelContainer"}>
          <div key={"header" + uniqueKey++} className={"PanelHeader"}>
            <div key={"header" + uniqueKey++} onClick={() => openPanel(false)}>
              <Icon IconName={"Close"} size={"36px"} fill={'#0c64c5'} />
            </div>
          </div>
          <div key={"header" + uniqueKey++} className={"PanelContent"}>
            {props.menuItems!.map((item) => (
              <p key={"header" + uniqueKey++}>
                <Link to={item.url} key={"header" + uniqueKey++} target={item.target} onClick={() => openPanel(false)}>
                  {item.label}
                </Link></p>
            ))}
          </div>
        </div>
      </div>
    </div>
  </div>
  );
}