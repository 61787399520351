import { IFooterProps } from '../../interfaces';
import "./FooterStyle.scss";

export default function Footer(props: IFooterProps) {
    return (<div className="outerComponentCenterContainer" id="SiteFooter">
        <div className="innerComponentContainer">
            <div className="footerContainer">
                <div className="FooterColumn1">
                    <div className='FooterLogoContainer'>
                        <img src='./Logo.png' alt="logo" className='logoImage' />
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: props!.companyDetails }} />
                    <div className="socialMediaIcon" dangerouslySetInnerHTML={{ __html: props!.socialMediaUrls }} />
                </div>
                <div className="FooterColumn2" dangerouslySetInnerHTML={{ __html: props!.QuickLinks }} />
                <div className="FooterColumn3" dangerouslySetInnerHTML={{ __html: props!.address }} />
                <div className="FooterColumn4" dangerouslySetInnerHTML={{ __html: props!.contact }} />
            </div>
        </div>
    </div>
    );
}