import { IInfoCardProps } from "../../interfaces";
import ScheduleCall from "../ScheduleCall/ScheduleCall";
import "./InfoSectionStyle.scss";
export default function InfoSection(props: IInfoCardProps) {
    const classname = "outerComponentCenterContainer " + props.OverrideThemeClassName;
    return (<div className={classname} id="InfoSection">
        <div className="innerComponentContainer">
            <div className="LShapeImageonRight" id="DigitalDifferentiaters">
                <div className="InfoCardImage">
                    <img src={props.image!} alt={props.Title} />
                </div>
                {props.Title && <div className="InfoCardTitle" dangerouslySetInnerHTML={{ __html: props.Title }} />}
                {props.SubTitle && <div className="InfoCardSubTitle" dangerouslySetInnerHTML={{ __html: props.SubTitle }} />}
                {props.Description && <div className="InfoCardDetails" dangerouslySetInnerHTML={{ __html: props.Description! }} />}
                {props.ShowCallButton && <div className="InfoCardControl">
                    <ScheduleCall BookingLink={props.BookingLink!} ButtonText={props.ButtonText!} />
                </div>}
            </div>
        </div>
    </div>
    );
}