import "./SkillBoardStyle.scss";
import { ISkillBoardProps } from "../../interfaces";
import { HashLink as Link } from 'react-router-hash-link';
import { useState, useEffect } from "react";

function GetFontSize(divId: string) {
    const el = document.getElementById(divId);
    const size = el!.offsetWidth * 1.5 / el!.innerText.length;
    let re = 'clamp(16px, ' + size + 'px, 44px)';
    return re;
}

export default function SkillBoard(props: ISkillBoardProps) {
    const [businessCardFontSize, setbusinessCardFontSize] = useState("14px");
    const [uxCardFontSize, setuxCardFontSize] = useState("14px");
    const [workspaceCardFontSize, setworkspaceCardFontSize] = useState("14px");
    const [applicationCardFontSize, setapplicationCardFontSize] = useState("14px");
    const [cloudCardFontSize, setcloudCardFontSize] = useState("14px");
    const [aiCardFontSize, setaiCardFontSize] = useState("14px");
    useEffect(() => {
        setbusinessCardFontSize(GetFontSize("businessCard"));
        setuxCardFontSize(GetFontSize("uxCard"));
        setworkspaceCardFontSize(GetFontSize("workspaceCard"));
        setapplicationCardFontSize(GetFontSize("applicationCard"));
        setcloudCardFontSize(GetFontSize("cloudCard"));
        setaiCardFontSize(GetFontSize("aiCard"));
    }, []);

    const classname = "outerComponentCenterContainer " + props.OverrideThemeClassName;
    return (<div className={classname} id="SkillBoard">
        <div className="innerComponentContainer">
            {props.Description && <div className="SkillCardTitle" dangerouslySetInnerHTML={{ __html: props.Description }} />}
            {props.Title && <div className="SkillCardDescription" dangerouslySetInnerHTML={{ __html: props.Title }} />}
            <div className="SkillCardContainer">
                <Link to={window.location.origin + "\\" + props.SkillCardItems![0].Link} id="businessCard" className="businessCard LableStyle" style={{ fontSize: businessCardFontSize }}>
                    <strong dangerouslySetInnerHTML={{ __html: props.SkillCardItems![0].Title }} />
                </Link>
                <Link to={window.location.origin + "\\" + props.SkillCardItems![1].Link} id="uxCard" className="uxCard LableStyle" style={{ fontSize: uxCardFontSize }}>
                    <strong dangerouslySetInnerHTML={{ __html: props.SkillCardItems![1].Title }} />
                </Link>
                <Link to={window.location.origin + "\\" + props.SkillCardItems![2].Link} id="cloudCard" className="cloudCard LableStyle" style={{ fontSize: cloudCardFontSize }}>
                    <strong dangerouslySetInnerHTML={{ __html: props.SkillCardItems![2].Title }} />
                </Link>
                <Link to={window.location.origin + "\\" + props.SkillCardItems![3].Link} id="workspaceCard" className="workspaceCard LableStyle" style={{ fontSize: workspaceCardFontSize }}>
                    <strong dangerouslySetInnerHTML={{ __html: props.SkillCardItems![3].Title }} />
                </Link>
                <Link to={window.location.origin + "\\" + props.SkillCardItems![4].Link} id="applicationCard" className="applicationCard LableStyle" style={{ fontSize: applicationCardFontSize }}>
                    <strong dangerouslySetInnerHTML={{ __html: props.SkillCardItems![4].Title }} />
                </Link>
                <Link to={window.location.origin + "\\" + props.SkillCardItems![5].Link} id="aiCard" className="aiCard LableStyle" style={{ fontSize: aiCardFontSize }}>
                    <strong dangerouslySetInnerHTML={{ __html: props.SkillCardItems![5].Title }} />
                </Link>
            </div>
        </div >
    </div >
    );
}