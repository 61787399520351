import { IPageCardProps } from "../../interfaces";
import { HashLink as Link } from 'react-router-hash-link';
import { Icon } from '../../themes/ItemStyles';
import "./PageCardStyle.scss";

export default function PageCard(props: IPageCardProps) {
    const classname = "outerComponentCenterContainer " + props.OverrideThemeClassName;
    let returnVal: any;
    switch (props.CardType) {
        case "LeftIconCard":
            returnVal = getLeftIconCard(props, classname)
            break;
        case "RightIconCard":
            returnVal = getRightIconCard(props, classname)
            break;
 
        case "TopImageCard":
            returnVal = getTopImageCard(props, classname)
            break;
        case "LeftImageCard":
            returnVal = getLeftImageCard(props, classname)
            break;
    }
    return (returnVal);
}
function getLeftImageCard(props: IPageCardProps, classnametoapply: string) {
    let uniqueKey = 0;
    return (
        <div key={"LeftImageCard" + uniqueKey++} className={classnametoapply} id="PageCard">
            <div key={"LeftImageCard" + uniqueKey++} className="innerComponentContainer">
                {props.Title && <div key={"LeftImageCard" + uniqueKey++} className="PageCardTitle" dangerouslySetInnerHTML={{ __html: props.Title }} />}
                {props.SubTitle && <div key={"LeftImageCard" + uniqueKey++} className="PageCardSubTitle" dangerouslySetInnerHTML={{ __html: props.SubTitle }} />}
                <div key={"LeftImageCard" + uniqueKey++} className="TwoColumnFlexContainer">
                    {props.items!.map((item) => (
                        <div key={"LeftImageCard" + uniqueKey++} className="LeftImageFlexCardContainer LightWhiteBack">
                            <div>
                                <img src={item.Image!} className="LeftImageFlexArea smallZoomable" alt={item.Title} />
                            </div>
                            <div className="LeftImageFlexContentContainer">
                                <div dangerouslySetInnerHTML={{ __html: item.Title! }} />
                                <div dangerouslySetInnerHTML={{ __html: item.Details! }} />
                            </div>
                        </div>
                    ))}
                </div>
            </div><br /><br />
        </div>);
}
function getTopImageCard(props: IPageCardProps, classnametoapply: string) {
    let uniqueKey = 0;
    return (
        <div key={"TopImageCard" + uniqueKey++} className={classnametoapply} id="PageCard">
            <div key={"TopImageCard" + uniqueKey++} className="innerComponentContainer">
                {props.Title && <div key={"TopImageCard" + uniqueKey++} className="PageCardTitle" dangerouslySetInnerHTML={{ __html: props.Title }} />}
                {props.SubTitle && <div key={"TopImageCard" + uniqueKey++} className="PageCardSubTitle" dangerouslySetInnerHTML={{ __html: props.SubTitle }} />}
                <div key={"TopImageCard" + uniqueKey++} className="ThreeColumnContainer">
                    {props.items!.map((item) => (
                        <div key={"TopImageCard" + uniqueKey++} className="ImageCardContainer LightWhiteBack">
                            <div key={"TopImageCard" + uniqueKey++} className="ImageCardImageArea">
                                <img key={"TopImageCard" + uniqueKey++} src={item.Image!} className="smallZoomable" alt={item.Title} />
                            </div>
                            <div key={"TopImageCard" + uniqueKey++} className="ImageCardContentHeader" dangerouslySetInnerHTML={{ __html: item.Title! }} />
                            <div key={"TopImageCard" + uniqueKey++} className="ImageCardContentArea" dangerouslySetInnerHTML={{ __html: item.Details! }} />
                            <div key={"TopImageCard" + uniqueKey++} className="ImageCardFooterArea">
                                <Link to={item.Url!} key={"TopImageCard" + uniqueKey++} >
                                    {props.LinkText}
                                </Link>
                            </div></div>
                    ))}
                </div>
            </div><br /><br />
        </div>);
}

function getLeftIconCard(props: IPageCardProps, classnametoapply: string) {
    let uniqueKey = 0;
    return (
        <div key={"LeftIconCard" + uniqueKey++} className={classnametoapply} id="PageCard">
            <div key={"LeftIconCard" + uniqueKey++} className="innerComponentContainer">
                {props.Title && <div key={"LeftIconCard" + uniqueKey++} className="PageCardTitle" dangerouslySetInnerHTML={{ __html: props.Title }} />}
                {props.SubTitle && <div key={"LeftIconCard" + uniqueKey++} className="PageCardSubTitle" dangerouslySetInnerHTML={{ __html: props.SubTitle }} />}
                <div key={"LeftIconCard" + uniqueKey++} className="TwoColumnContainer">
                    {props.items!.map((item) => (
                        <div key={"LeftIconCard" + uniqueKey++} className="iconCard">
                            <div className={"CardIconAreaContainer"}>
                                <div key={"LeftIconCard" + uniqueKey++} className="CardIconArea" style={{ backgroundColor: item.IconBackColor }}>
                                    {
                                        <Icon IconName={item.Icon!} size={"80%"} fill={item.IconColor!} />
                                    }
                                </div>
                                <div key={"LeftIconCard" + uniqueKey++} className="sideTextArea" dangerouslySetInnerHTML={{ __html: item.sideText! }} />    
                            </div>
                            <div key={"LeftIconCard" + uniqueKey++} className="CardHeadingArea" dangerouslySetInnerHTML={{ __html: item.Title! }} />
                            <div key={"LeftIconCard" + uniqueKey++} className="CardContentArea" dangerouslySetInnerHTML={{ __html: item.Details! }} />
                            <div key={"LeftIconCard" + uniqueKey++} className="CardFooterArea">
                                <Link to={item.Url!} key={"LeftIconCard" + uniqueKey++} >
                                    {props.LinkText}
                                </Link>
                            </div>
                        </div>
                    ))}
                </div>
            </div><br /><br />
        </div>);
}
function getRightIconCard(props: IPageCardProps, classnametoapply: string) {
    let uniqueKey = 0;
    return (
        <div key={"RightIconCard" + uniqueKey++} className={classnametoapply} id="PageCard">
            <div key={"RightIconCard" + uniqueKey++} className="innerComponentContainer">
                {props.Title && <div key={"RightIconCard" + uniqueKey++} className="PageCardTitle" dangerouslySetInnerHTML={{ __html: props.Title }} />}
                {props.SubTitle && <div key={"RightIconCard" + uniqueKey++} className="PageCardSubTitle" dangerouslySetInnerHTML={{ __html: props.SubTitle }} />}
                <div key={"RightIconCard" + uniqueKey++} className="TwoColumnContainer">
                    {props.items!.map((item) => (
                        <div key={"RightIconCard" + uniqueKey++} className="rightIconCard">
                            <div key={"RightIconCard" + uniqueKey++} className={"RightCardHeadArea"}>
                                <div key={"RightIconCard" + uniqueKey++} dangerouslySetInnerHTML={{ __html: item.Title! }} />
                                <div key={"RightIconCard" + uniqueKey++} className="RightCardIconArea" style={{ backgroundColor: item.IconBackColor }}>
                                    <Icon IconName={item.Icon!} size={"75%"} fill={item.IconColor!} />
                                </div>
                            </div>
                            <div key={"RightIconCard" + uniqueKey++} className="CardContentArea" dangerouslySetInnerHTML={{ __html: item.Details! }} />
                            <div key={"RightIconCard" + uniqueKey++} className="CardFooterArea">
                                <Link to={item.Url!} key={"RightIconCard" + uniqueKey++} >
                                    {props.LinkText}
                                </Link>
                            </div>
                        </div>
                    ))}
                </div>
            </div><br /><br />
        </div>);
}
